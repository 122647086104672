<template>
    <div>
      <etebs-list></etebs-list>
    </div>
</template>
  
  <script>
  export default {
  }
  </script>
  